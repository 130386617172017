const browserUpdate = require('browser-update');

browserUpdate({
    required: {
        e: -4,
        f: -3,
        o: -3,
        s: -1,
        c: -12,
    },
    insecure: false,
    api: 2024.03,
    text: {
        msgmore: 'Update your browser for more security, speed and the best experience on this site.  Using this browser may cause issues with rendering on this page.',
    },
})
